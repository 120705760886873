<!-- src/components/AboutUs.vue -->
<template>
    <section id="about_us" class="w-full py-12 relative overflow-hidden min-h-[500px] flex items-center justify-center"
        :style="{ minHeight: '90vh', backgroundColor: 'var(--background-color-card)', color: 'var(--text-dark-enforce)' }">

        <!-- LazyImage component for background image -->
        <LazyImage imageName="c130" imgClass="absolute top-0 left-0 w-full h-full object-cover" />

        <!-- Dark overlay to improve text visibility -->
        <div class="absolute inset-0 bg-black opacity-50"></div>

        <!-- Content container with relative positioning to overlay on the background -->
        <div class="container mx-auto px-4 relative z-10">
            <h2 class="text-3xl font-semibold text-center mb-8">About Us</h2>
            <p class="text-lg text-center max-w-2xl mx-auto">
                {{ AboutUs }}
            </p>
        </div>
    </section>
</template>

<script>
import { CompanyInfoContent } from '@/SiteContents.js';
import LazyImage from '@/components/LazyImage.vue';

export default {
    name: 'AboutUs',
    components: { LazyImage },
    data() {
        return {
            AboutUs: CompanyInfoContent, // Use imported company information
        };
    },
};
</script>

<style scoped>
section {
    position: relative;
    height: auto;
    min-height: 500px; /* Set the minimum height */
    display: flex; /* Apply Flexbox for centering */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    transition: background-color 0.2s ease, color 0.2s ease; /* Smooth transitions */
}

img {
    /* Ensure the image covers the entire section */
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.overlay {
    /* Overlay settings */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.245);
    /* Dark overlay */
}
</style>
