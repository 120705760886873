<!-- src/components/LandingView.vue -->
<template>
    <section id="landing" class="relative w-full overflow-hidden min-h-[500px] flex items-center justify-center"
        :style="{ minHeight: '90vh' }">

        <!-- Lazy-loaded background image with zoom and position effect -->
        <LazyImage 
            imageName="jf17" 
            imgClass="absolute inset-0 w-full h-full object-cover"
            :imageStyle="{ 
                transform: `translateY(${offsetY}px) scale(${zoomScale})`, 
                objectPosition: objectPosition 
            }" 
        />

        <!-- Background Overlay for dark mode -->
        <div class="absolute inset-0 bg-black opacity-50 dark:opacity-50"></div>

        <!-- Content on top of the background -->
        <div class="relative flex items-center justify-center h-full">
            <div class="text-center">
                <!-- Company Name -->
                <h1 class="text-5xl font-bold text-white">Indus Aero Electrics</h1>
                <!-- Company Tagline -->
                <p class="mt-4 text-xl text-gray-300">Aerospace Interconnect Solutions</p>
            </div>
        </div>

    </section>
</template>

<script>
import LazyImage from '@/components/LazyImage.vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';

export default {
    name: 'LandingView',
    components: { LazyImage },
    setup() {
        const offsetY = ref(0);
        const screenWidth = ref(window.innerWidth); // Reactive variable for screen width

        // Function to apply parallax effect
        const parallaxEffect = () => {
            offsetY.value = window.scrollY * 0.3; // Adjust the multiplier to control speed
        };

        // Function to handle screen resize
        const handleResize = () => {
            screenWidth.value = window.innerWidth; // Update screen width on resize
        };

        // Compute zoom scale dynamically based on screen width
        const zoomScale = computed(() => {
            const minZoom = 1; // Minimum zoom-out value
            const maxZoom = 1.5; // Maximum zoom-in value
            const minWidth = 320; // A reasonable minimum width for most devices
            const maxWidth = 1920; // A reasonable maximum width for most devices

            // Map the screen width to a value between minZoom and maxZoom
            const zoomRange = maxZoom - minZoom;
            const widthRange = maxWidth - minWidth;

            // Calculate the zoom scale based on the current screen width
            const zoomLevel = minZoom + ((screenWidth.value - minWidth) / widthRange) * zoomRange;

            // Constrain zoom level between minZoom and maxZoom
            return Math.min(Math.max(zoomLevel, minZoom), maxZoom);
        });

        // Compute object position dynamically for horizontal adjustment, but fixed at "top"
        const objectPosition = computed(() => {
            const minPosition = 10; // Fully left-aligned
            const maxPosition = 50; // Centered horizontally

            // Calculate horizontal position percentage based on screen width
            const positionPercentage = minPosition + ((screenWidth.value - 320) / (1920 - 320)) * (maxPosition - minPosition);
            
            return `${positionPercentage}% top`; // Fixed at top for vertical alignment
        });

        // Register scroll and resize event listeners
        onMounted(() => {
            window.addEventListener('scroll', parallaxEffect);
            window.addEventListener('resize', handleResize);
        });

        // Clean up the event listeners when component is unmounted
        onUnmounted(() => {
            window.removeEventListener('scroll', parallaxEffect);
            window.removeEventListener('resize', handleResize);
        });

        return {
            offsetY,
            zoomScale,
            objectPosition
        };
    },
};
</script>

<style scoped>
.object-cover {
    object-fit: cover;
    will-change: transform, object-position;
    transition: transform 0.2s ease-out, object-position 0.2s ease-out;
}
</style>
