<!-- src/views/HomePage.vue -->
<template>
    <div>
        <LandingView :theme="theme" />
        <ServiceCarousel :theme="theme" />
        <AboutUs :theme="theme" />
        <AssociationsList :theme="theme" />
        <ContactUs :theme="theme" />
        <AddressSection :theme="theme" />
        <MadeBy :theme="theme" />
    </div>
</template>

<script>
import LandingView from '@/components/LandingView.vue';
import ServiceCarousel from '@/components/ServiceCarousel.vue';
import AboutUs from '@/components/AboutUs.vue';
import AssociationsList from '@/components/AssociationsList.vue';
import ContactUs from '@/components/ContactUs.vue';
import AddressSection from '@/components/AddressSection.vue';
import MadeBy from '@/components/MadeBy.vue';

export default {
    name: 'HomePage',
    props: ['theme'], // Accept the theme prop
    components: {
        LandingView,
        ServiceCarousel,
        AboutUs,
        AssociationsList,
        ContactUs,
        AddressSection,
        MadeBy,
    },
};
</script>
