<!-- src/components/SplashScreen.vue -->
<template>
    <div v-show="isVisible" class="splash-screen text-black dark:text-slate-300 bg-white dark:bg-gray-900">
    <div class="relative flex items-center justify-center">
        <div class="halo absolute w-56 h-56 rounded-full"></div>
        <LazyImage imageName="iae_logo" :class="['relative z-10 logo', { 'fade-out': isIconFadeOut }]" />
    </div>
</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import LazyImage from '@/components/LazyImage.vue';

const isVisible = ref(true);
const isIconFadeOut = ref(false);

onMounted(() => {
    // Simulate loading completion
    setTimeout(() => {
        // Start fading out the icon
        isIconFadeOut.value = true;

        // Hide the splash screen after the logo fades out
        setTimeout(() => {
            isVisible.value = false;
        }, 1000); // Duration of icon fade-out
    }, 2000); // Adjust the speed of the loading simulation
});
</script>

<style scoped>
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Soft Pulsating Halo Effect */
.halo {
    background: radial-gradient(circle, rgba(0, 217, 255, 0.3) 0%, transparent 70%);
    animation: pulse 1s infinite;
}

.logo {
    width: 8rem;
    height: auto;
    transition: opacity 1s ease-in-out;
    opacity: 1;
}

.logo.fade-out {
    opacity: 0;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }

    50% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    100% {
        transform: scale(1);
        opacity: 0.5;
    }
}
</style>