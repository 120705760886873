// src/SiteContents.js

const SiteColors =
{
    buttonColorLight         : "#ffffff",
    buttonColorDark          : "#006e7d",
    linkHighlightColor       : "#2563eb",
    backgroundColorLight     : "#ffffff",
    backgroundColorDark      : "#1f2937",
    textColorLight           : "#1f2937",
    textColorDark            : "#f3f4f6",
    backgroundColorCardLight : "rgb(230, 230, 230)",
    backgroundColorCardDark  : "#374151",
    boxShadowLight           : "0 4px 6px rgba(0, 0, 0, 0.1)",
    boxShadowDark            : "0 4px 6px rgba(0, 0, 0, 0.3)",
    boxShadowHoverLight      : "0 6px 8px rgba(0, 0, 0, 0.15)",
    boxShadowHoverDark       : "0 6px 8px rgba(0, 0, 0, 0.4)",
    borderRadius             : "0.5rem"
};

const ServicesContent = [
    {
        name        : 'On Aircraft Capabilities',
        imageName   : 'harness',
        heading     : 'Upgrades and Maintenance',
        description : 'Our team has the capability to manufacture wire harnesses and to do complete aircraft rewires on site.',
    },
    {
        name        : 'System Design',
        imageName   : 'avionics',
        heading     : 'Cutting-Edge Avionics Design',
        description : 'We have a dedicated R&D team to design, develop, and improve aircraft electrical/avionics components. We can also manage avionics upgrades of all types of aircraft.',
    },
    {
        name        : 'Spares Support',
        imageName   : 'connectors',
        heading     : 'Aircraft Spares Sales and Support',
        description : 'We have a large inventory of military standard connectors, contact pins, back-shells, aircraft wire, and other aerospace interconnect parts.',
    },
];

const AssociationsContent = [
    { name: 'Amphenol',        imageName: 'logo_amphenol'        },
    { name: 'Conesys',         imageName: 'logo_conesys'         },
    { name: 'Glenair',         imageName: 'logo_glenair'         },
    { name: 'TE Connectivity', imageName: 'logo_te_connectivity' },
    { name: 'TriStar',         imageName: 'logo_tristar'         },
    { name: 'ITT Cannon',      imageName: 'logo_itt_cannon'      },
];

const CompanyInfoContent = "Since 2018, Indus Aero Electrics has been working with clients on a variety of innovative aviation and aerospace projects. Our main purpose is to serve clients in the aerospace sector with practical engineering services.";

const CompanyAddress = {
    name   : 'Indus Aero Electrics',
    // street : 'Office 123',
    suite  : 'National Aerospace Science and Technology Park (NASTP)',
    state  : 'Chaklala Cantt, Rawalpindi, Punjab, Pakistan',
    phone  : '+92 333 5164167',
    email  : 'imranmehmood65@yahoo.com',
};

// New content: Google Maps Embed URL
const GoogleMapsUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.8361537224305!2d73.09824371125586!3d33.609555447474776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfeb6bb5c0b649%3A0xb15572084afd9b0e!2sNational%20Aerospace%20Science%20and%20Technology%20Park%20(NASTP)!5e0!3m2!1sen!2sde!4v1725542904433!5m2!1sen!2sde';


const Services = ServicesContent.map(service => ({ name: service.name }));

export {
    Services,
    ServicesContent,
    AssociationsContent,
    SiteColors,
    CompanyInfoContent,
    CompanyAddress,
    GoogleMapsUrl
};
