// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import ServicePage from '../views/ServicePage.vue';

const routes = [
    { path: '/', name: 'HomePage', component: HomePage },
    { path: '/service/:serviceName', name: 'ServicePage', component: ServicePage, props: true },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
