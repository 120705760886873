<!-- src/components/ContactUs.vue -->
<template>
    <section id="contact" class="w-full py-12 flex items-center justify-center"
        :style="{ backgroundColor: 'var(--background-color-card)', color: 'var(--text-color)' }">

        <div class="container mx-auto px-4">
            <h2 class="text-3xl font-semibold text-center mb-8">Contact Us</h2>

            <!-- Contact form -->
            <form @submit.prevent="submitForm" class="max-w-xl mx-auto p-6"
                :style="{ backgroundColor: 'var(--background-color-card)', color: 'var(--text-color)' }">
                <div class="mb-4">
                    <label for="name" class="block text-lg mb-2">Full Name</label>
                    <input type="text" id="name" v-model="name" class="w-full p-3 border rounded"
                        :style="{ backgroundColor: 'var(--background-color)', color: 'var(--text-color)', borderColor: 'var(--border-color)' }"
                        placeholder="Your Name" required />
                </div>
                <div class="mb-4">
                    <label for="email" class="block text-lg mb-2">Email</label>
                    <input type="email" id="email" v-model="email" class="w-full p-3 border rounded"
                        :style="{ backgroundColor: 'var(--background-color)', color: 'var(--text-color)', borderColor: 'var(--border-color)' }"
                        placeholder="Your Email" required />
                </div>
                <div class="mb-4">
                    <label for="message" class="block text-lg mb-2">Message</label>
                    <textarea id="message" v-model="message" class="w-full p-3 border rounded"
                        :style="{ backgroundColor: 'var(--background-color)', color: 'var(--text-color)', borderColor: 'var(--border-color)' }" rows="5"
                        placeholder="Your Message" required></textarea>
                </div>
                <div class="flex justify-center">
                    <button type="submit"
                        :style="{ backgroundColor: 'var(--button-color)', color: 'var(--text-color)', borderRadius: 'var(--border-radius)' }"
                        class="px-6 py-3">Send Message</button>
                </div>
            </form>

            <!-- Pop-up Feedback Modal -->
            <div v-if="showPopup" class="fixed inset-0 flex items-center justify-center z-50">
                <div class="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full z-50">
                    <h3 class="text-xl font-semibold mb-4 text-center">{{ popupTitle }}</h3>
                    <p class="text-center mb-4">{{ popupMessage }}</p>
                    <div class="flex justify-center">
                        <button @click="closePopup" class="bg-blue-500 text-white px-4 py-2 rounded">Close</button>
                    </div>
                </div>
                <div @click="closePopup" class="fixed inset-0 bg-black opacity-50 z-40"></div>
                <!-- Background overlay -->
            </div>
        </div>
    </section>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'ContactUs',
    setup() {
        const name = ref('');
        const email = ref('');
        const message = ref('');
        const showPopup = ref(false);
        const popupTitle = ref('');
        const popupMessage = ref('');   

        const submitForm = async () => {
            try {
                const response = await fetch('https://api.web3forms.com/submit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: JSON.stringify({
                        access_key: '3f5564c8-abe2-4ad3-8ce9-e322b8a19050',
                        name: name.value,
                        email: email.value,
                        message: message.value,
                    }),
                });

                const result = await response.json();
                if (result.success) {
                    popupTitle.value = 'Message Sent';
                    popupMessage.value = 'Your message has been sent successfully!';
                    showPopup.value = true; // Show the success popup
                    // Clear the form after successful submission
                    name.value = '';
                    email.value = '';
                    message.value = '';
                } else {
                    popupTitle.value = 'Error';
                    popupMessage.value = result.message || 'An error occurred while sending your message.';
                    showPopup.value = true; // Show the error popup
                }
            } catch (error) {
                popupTitle.value = 'Error';
                popupMessage.value = 'An unexpected error occurred. Please try again later.';
                showPopup.value = true; // Show the error popup
            }
        };

        const closePopup = () => {
            showPopup.value = false; // Close the popup
        };

        return {
            name,
            email,
            message,
            showPopup,
            popupTitle,
            popupMessage,
            submitForm,
            closePopup,
        };
    },
};
</script>

<style scoped>
/* Apply smooth transitions for background, text, and border color */
section, form, input, textarea, button {
    transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
}

h2, p, label {
    transition: color 0.2s ease;
}
</style>
