<template>
    <footer id="made-by" class="w-full py-2 flex items-center justify-center space-x-4"
        :style="{ backgroundColor: 'var(--background-color-card)', color: 'var(--text-color)', minHeight: '5vh' }">

        <!-- Text Content with Stylish Copyright Symbol -->
        <p class="text-sm text-center">©️ Indus Aero Electrics, 2024</p>

        <!-- Separator -->
        <span class="text-sm text-center">|</span>

        <!-- Text Content -->
        <p class="text-sm text-center">This website has proudly been developed in-house</p>
    </footer>
</template>

<script>
export default {
    name: 'MadeBy',
};
</script>

<style scoped>
footer {
    padding: 8px 0;
    transition: background-color 0.2s ease, color 0.2s ease;
}
</style>
