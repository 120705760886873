<!-- src/components/AssociationsList.vue -->
<template>
    <section id="associations" class="py-12 flex items-center justify-center" :style="{ backgroundColor: 'var(--background-color)', minHeight: '90vh' }">
        
        <!-- Section Contents -->
        <div class="container mx-auto px-4">
            <h2 class="text-3xl font-semibold text-center mb-8" :style="{ color: 'var(--text-color)' }">Our Inventory Range</h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 gap-y-10 justify-items-center">
                <div v-for="(customer, index) in customers" :key="index"
                    class="flex justify-center items-center min-h-[120px]">
                    <LazyImage :imageName="customer.imageName" imgClass="h-16 object-contain" />
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import { AssociationsContent } from '@/SiteContents.js';
import LazyImage from '@/components/LazyImage.vue';

export default {
    name: 'AssociationsList',
    components: { LazyImage },
    data() {
        return {
            customers: AssociationsContent, // Use imported customer data
        };
    },
};
</script>

<style scoped>
section {
    transition: background-color 0.2s ease, color 0.2s ease;
}
h2, p {
    transition: color 0.2s ease;
}
</style>
