<template>
    <section id="address" class="w-full py-12 flex items-center justify-center"
        :style="{ backgroundColor: 'var(--background-color)', color: 'var(--text-color)', minHeight: '90vh' }">
        <div class="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">

            <!-- Company Address -->
            <div class="w-full md:w-1/2 mb-8 md:mb-0">
                <h2 class="text-3xl font-semibold mb-4">Our Office</h2>
                <p class="text-lg mb-2">{{ companyAddress.name }}</p>
                <p class="text-lg mb-2">{{ companyAddress.street }}</p>
                <p class="text-lg mb-2">{{ companyAddress.suite }}</p>
                <p class="text-lg mb-2">{{ companyAddress.state }}</p>
                <p class="text-lg mb-2">Phone: {{ companyAddress.phone }}</p>
                <p class="text-lg mb-2">Email: {{ companyAddress.email }}</p>
            </div>

            <!-- Google Maps Integration -->
            <div class="w-full md:w-1/2">
                <iframe width="100%" height="300" frameborder="0" style="border:0" :src="googleMapsUrl"
                    allowfullscreen></iframe>
            </div>

        </div>
    </section>
</template>

<script>
import { CompanyAddress, GoogleMapsUrl } from '@/SiteContents.js';

export default {
    data() {
        return {
            companyAddress: CompanyAddress,
            googleMapsUrl: GoogleMapsUrl
        };
    }
};
</script>

<style scoped>
section {
    transition: background-color 0.2s ease, color 0.2s ease;
}

h2 {
    transition: color 0.2s ease;
}

.service-item {
    transition: background-color 0.2s ease, color 0.2s ease;
}

.service-item h3 {
    transition: color 0.2s ease;
}
</style>
