<!-- src/components/ServiceCarousel.vue -->
<template>
    <section id="services" class="bg-white dark:bg-gray-800 py-12 min-h-[500px] flex items-center justify-center"
        :style="{ backgroundColor: 'var(--background-color)', minHeight: '90vh' }">

        <div class="container mx-auto px-4">
            <h2 class="text-3xl font-semibold text-center mb-8 text-gray-900 dark:text-white">Our Services</h2>
            <!-- Dynamic grid for service items with center alignment -->
            <div :class="gridClasses" class="gap-4 py-4 mx-auto justify-items-center">
                <router-link v-for="(service, index) in services" :key="index"
                    :to="{ name: 'ServicePage', params: { serviceName: service.name } }"
                    class="relative overflow-hidden flex items-center justify-center w-full h-48 rounded-lg shadow-md">
                    <!-- Service image -->
                    <LazyImage :imageName="service.imageName" imgClass="absolute inset-0 w-full h-full object-cover" />
                    <!-- Overlay for hover effect -->
                    <div
                        class="absolute inset-0 bg-black bg-opacity-50 transition duration-300 ease-in-out hover:bg-opacity-20">
                    </div>
                    <!-- Service name text -->
                    <div class="relative z-10">
                        <h3 class="text-xl font-medium text-white">{{ service.name }}</h3>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { ServicesContent } from '@/SiteContents.js';
import LazyImage from '@/components/LazyImage.vue';

export default {
    name: 'ServiceCarousel',
    components: { LazyImage },
    data() {
        return {
            services: ServicesContent,
        };
    },
    computed: {
        gridClasses() {
            // Determine the maximum number of columns dynamically, capped at 3
            const maxColumns = Math.min(this.services.length, 3); // Maximum 3 columns for services
            return {
                'grid': true,
                'grid-cols-1': this.services.length <= 1,
                'sm:grid-cols-2': this.services.length > 1,
                'md:grid-cols-3': this.services.length > 2 && maxColumns >= 3, // Cap at 3 columns for medium screens and above
            };
        }
    }
};
</script>

<style scoped>
section {
    transition: background-color 0.2s ease, color 0.2s ease;
    min-height: 500px; /* Minimum height for the section */
    display: flex; /* Flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

h2 {
    transition: color 0.2s ease;
}

.service-item {
    transition: background-color 0.2s ease, color 0.2s ease;
}

.service-item h3 {
    transition: color 0.2s ease;
}
</style>
