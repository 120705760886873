<!-- src/App.vue -->
<template>
    <div :class="{ 'pt-25': atTop }">
        <SplashScreen v-if="isLoading" />

        <!-- Conditionally render Navigation Bar after splash screen -->
        <NavigationBar v-if="!isLoading" @theme-changed="handleThemeChange" @scroll-up="atTop = true"
            @scroll-down="atTop = false" />

        <!-- Render active component contents -->
        <router-view v-if="!isLoading" :key="theme" :theme="theme" />
    </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import SplashScreen from './components/SplashScreen.vue';

export default {
    components: {
        NavigationBar,
        SplashScreen,
    },
    data() {
        return {
            theme: localStorage.getItem('theme') || 'light', // Default to light mode
            isLoading: true, // Control splash screen visibility
            atTop: true, // Control padding based on scroll position
        };
    },
    methods: {
        handleThemeChange(newTheme) {
            this.theme = newTheme;
        },
    },
    mounted() {
        const currentTheme = localStorage.getItem('theme') || 'light';
        document.documentElement.classList.toggle('dark', currentTheme === 'dark');

        setTimeout(() => {
            this.isLoading = false; // Hide splash screen after load
        }, 2000); // Adjust this duration as needed
    },
};
</script>

<style>
body {
    font-family: 'Questrial', sans-serif;
}
</style>
