<template>
    <div>
        <!-- Low-resolution image -->
        <img
            :src="lowResSrc"
            :class="imgClass"
            :style="imageStyle"
            @load="onLowResLoad"
            @error="onLowResError"
            v-show="!isMedResLoaded && !isHighResLoaded"
            alt="Low Resolution Image"
        />
        <!-- Medium-resolution image -->
        <img
            :src="medResSrc"
            :class="imgClass"
            :style="imageStyle"
            @load="onMedResLoad"
            @error="onMedResError"
            v-show="isMedResLoaded && !isHighResLoaded"
            alt="Medium Resolution Image"
        />
        <!-- High-resolution image -->
        <img
            :src="highResSrc"
            :class="imgClass"
            :style="imageStyle"
            @load="onHighResLoad"
            @error="onHighResError"
            v-show="isHighResLoaded"
            alt="High Resolution Image"
        />
    </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
    props: {
        imageName     : String, // Base name of the image without resolution or extension
        imgClass      : String,
        imageStyle    : Object,
        onExternalLoad: Function,
    },

    setup(props) {
        // Computed properties to dynamically generate image URLs
        const lowResSrc  = computed(() => require(`@/assets/images/low/${props.imageName}.webp`));
        const medResSrc  = computed(() => require(`@/assets/images/medium/${props.imageName}.webp`));
        const highResSrc = computed(() => require(`@/assets/images/high/${props.imageName}.webp`));

        const isMedResLoaded  = ref(false);
        const isHighResLoaded = ref(false);

        const onLowResLoad = () => {
            if (props.onExternalLoad) {
                props.onExternalLoad();
            }
        };

        const onMedResLoad = () => {
            isMedResLoaded.value = true; // Set medium-res as loaded
        };

        const onHighResLoad = () => {
            isHighResLoaded.value = true; // Set high-res as loaded
        };

        const onLowResError = () => {
            console.error("Failed to load low-res image");
        };

        const onMedResError = () => {
            console.error("Failed to load medium-res image");
        };

        const onHighResError = () => {
            console.error("Failed to load high-res image");
        };

        return {
            lowResSrc,
            medResSrc,
            highResSrc,
            isMedResLoaded,
            isHighResLoaded,
            onLowResLoad,
            onMedResLoad,
            onHighResLoad,
            onLowResError,
            onMedResError,
            onHighResError,
        };
    },
};
</script>

<style scoped>
img {
    transition: opacity 0.2s ease-in-out;
}
</style>
