<!-- src/components/NavigationBar.vue -->
<template>
    <header class="fixed w-full top-0 z-50 transition-all"
        :class="{ '-translate-y-full': !isVisible }"
        :style="{ backgroundColor: 'var(--background-color)', color: 'var(--text-color)', transition: 'background-color 0.2s ease, color 0.2s ease, transform 0.3s ease-in-out, box-shadow 0.2s ease' }">

        <!-- Logo and navigation buttons with full-width stretch -->
        <div class="flex justify-between items-center w-full px-4 py-4">

            <!-- Conditional rendering of the logo based on screen size -->
            <LazyImage :imageName="isLargeScreen ? 'iae_logo_long' : 'iae_logo'" imgClass="h-8 cursor-pointer"
                :imageStyle="{ display: 'block', height: '32px', width: 'auto' }"
                @click="navigateToSection('landing')" />

            <!-- Navigation buttons for medium and large screens -->
            <div class="flex items-center space-x-4" v-if="!isSmallScreen || isMediumScreen">
                <button @click="navigateToSection('landing')" class="hover:underline">Home</button>
                <button @click="navigateToSection('services')" class="hover:underline">Services</button>
                <button @click="navigateToSection('about_us')" class="hover:underline">About Us</button>
                <button @click="navigateToSection('contact')" class="hover:underline">Contact</button>
                <button @click="navigateToSection('address')" class="hover:underline">Address</button>
                <ThemeSwitcher />
            </div>

            <!-- Hamburger Menu Icon for small screens -->
            <div @click="toggleMenu" class="cursor-pointer md:hidden" v-if="isSmallScreen">
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path :class="{ 'hidden': menuOpen }" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
                    <path :class="{ 'hidden': !menuOpen }" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>

        </div>

        <!-- Collapsible menu for small screens -->
        <transition name="slide" @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @before-leave="beforeLeave" @leave="leave">
            <div v-if="menuOpen && isSmallScreen" class="w-full md:hidden overflow-hidden">
                
                <ul ref="menuContent" class="w-full text-right transition-opacity duration-300" :class="{ 'opacity-0': !menuItemsVisible }">
                    <li class="pr-4 py-1">
                        <button @click="navigateToSection('landing')" class="hover:underline w-full text-right">Home</button>
                    </li>
                    <li class="pr-4 py-1">
                        <button @click="navigateToSection('services')" class="hover:underline w-full text-right">Services</button>
                    </li>
                    <li class="pr-4 py-1">
                        <button @click="navigateToSection('about_us')" class="hover:underline w-full text-right">About Us</button>
                    </li>
                    <li class="pr-4 py-1">
                        <button @click="navigateToSection('contact')" class="hover:underline w-full text-right">Contact</button>
                    </li>
                    <li class="pr-4 py-1">
                        <button @click="navigateToSection('address')" class="hover:underline w-full text-right">Address</button>
                    </li>
                    <li class="py-2 flex justify-end">
                        <ThemeSwitcher class="theme-switcher-icon" @theme-switched="toggleMenu" />
                    </li>
                    <li class="py-1 flex justify-end">
                    </li>
                </ul>
            </div>
        </transition>
    </header>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import ThemeSwitcher from './ThemeSwitcher.vue';
import LazyImage from '@/components/LazyImage.vue';

const smallScreenLimit = 640;   // Small screen breakpoint, adjust as needed
const mediumScreenLimit = 1024;  // Medium screen breakpoint, adjust as needed

export default {
    components: {
        ThemeSwitcher,
        LazyImage,
    },
    setup() {
        const router = useRouter();
        const isVisible = ref(true);
        const isDarkMode = ref(document.documentElement.classList.contains('dark'));
        let lastScrollTop = 0;
        const menuOpen = ref(false);
        const menuItemsVisible = ref(false); // Flag to control menu item visibility
        const menuContent = ref(null); // Reference to the menu content for dynamic height calculation

        const isSmallScreen = ref(window.innerWidth < 768);
        const isMediumScreen = ref(window.innerWidth >= 641 && window.innerWidth < 768);
        const isLargeScreen = ref(window.innerWidth >= mediumScreenLimit);

        const headerClasses = computed(() => {
            return `fixed w-full top-0 z-50 ${isVisible.value ? '' : '-translate-y-full'} ${isDarkMode.value ? 'shadow-nav-dark' : 'shadow-nav-light'}`;
        });

        const toggleMenu = () => {
            menuOpen.value = !menuOpen.value;
            menuItemsVisible.value = false; // Hide items initially
        };

        const beforeEnter = (el) => {
            el.style.height = '0';
        };

        const enter = (el) => {
            if (menuContent.value) {
                // Calculate the height dynamically based on the content
                const fullHeight = menuContent.value.scrollHeight;
                el.style.transition = 'height 0.2s ease';
                el.style.height = `${fullHeight}px`;
            }
        };

        const afterEnter = () => {
            menuItemsVisible.value = true; // Show items after animation ends
        };

        const beforeLeave = (el) => {
            if (menuContent.value) {
                // Set the height to the current scrollHeight of the content
                el.style.height = `${menuContent.value.scrollHeight}px`;
            }
        };

        const leave = (el) => {
            el.style.transition = 'height 0.2s ease';
            el.style.height = '0';
        };

        const handleScroll = () => {
            const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
            isVisible.value = currentScrollTop <= lastScrollTop || currentScrollTop <= 10;
            lastScrollTop = Math.max(0, currentScrollTop);
        };

        const handleResize = () => {
            const width = window.innerWidth;

            isSmallScreen.value = (width < smallScreenLimit);
            isMediumScreen.value = (smallScreenLimit <= width) && (width <= mediumScreenLimit);
            isLargeScreen.value = (width > mediumScreenLimit);
        };

        const navigateToSection = (id) => {
            menuOpen.value = false;
            if (router.currentRoute.value.path !== '/') {
                router.push('/').then(() => {
                    setTimeout(() => scrollToSection(id), 100);
                });
            } else {
                scrollToSection(id);
            }
        };

        const scrollToSection = (id) => {
            const element = document.getElementById(id);
            if (element) {
                const topOffset = 25;
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
                const isScrollingUp = elementPosition < currentScrollTop;
                const shouldApplyOffset = id === 'landing' || isScrollingUp;
                const offsetPosition = shouldApplyOffset ? elementPosition - topOffset : elementPosition;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            }
        };

        onMounted(() => {
            window.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', handleResize);
            isDarkMode.value = document.documentElement.classList.contains('dark');
        });

        onUnmounted(() => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        });

        const closeMenu = () => {
            menuOpen.value = false;
        };

        return {
            isVisible,
            navigateToSection,
            isSmallScreen,
            isMediumScreen,
            isLargeScreen,
            toggleMenu,
            menuOpen,
            menuItemsVisible,
            menuContent, // Expose the ref to the template
            beforeEnter,
            enter,
            afterEnter,
            beforeLeave,
            leave,
            headerClasses,
            closeMenu,
        };
    },
};
</script>

<style scoped>
/* Slide transition for the menu */
.slide-enter-active,
.slide-leave-active {
    transition: height 0.2s ease;
}

.slide-enter,
.slide-leave-to {
    height: 0;
    overflow: hidden;
}

/* Style adjustments for theme switcher */
.theme-switcher-icon {
    display: flex;
    justify-content: flex-end; /* Ensure icon is aligned to the right */
    width: 40px; /* Maintain a fixed width for consistency */
}

.theme-switcher-button {
    width: 40px;
    display: flex;
    justify-content: right;
    align-items: right;
    transition: width 0.2s ease;
}
</style>
