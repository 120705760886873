<!-- src/views/ServicePage.vue -->
<template>
    <div class="container mx-auto py-20 px-4 sm:px-6 lg:px-8">
        <!-- Back Button -->
        <button @click="goBack" class="mb-6 flex items-center text-sm hover:underline"
            :style="{ color: 'var(--text-color)', borderColor: 'var(--button-color)' }">
            <!-- Back arrow icon -->
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                :style="{ color: 'var(--text-color)' }" stroke="currentColor" class="h-5 w-5 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            Back
        </button>

        <!-- Main content area with responsive flex layout -->
        <div class="flex flex-col lg:flex-row items-center gap-8">
            <!-- Image with rounded corners and shadow -->
            <div class="flex-shrink-0 w-full lg:w-1/2">
                <LazyImage :imageName="serviceContent.imageName" imgClass="rounded-lg shadow-md"
                    :imageStyle="{ width: '100%', height: 'auto' }" />
            </div>

            <!-- Text content with improved typography -->
            <div class="flex-1 text-center lg:text-left">
                <h2 class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white mb-6">
                    {{ serviceContent.heading }}
                </h2>
                <p class="text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
                    {{ serviceContent.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { ServicesContent } from '@/SiteContents.js'; // Import SiteContents
import LazyImage from '@/components/LazyImage.vue';
import { useRouter } from 'vue-router'; // Import useRouter for programmatic navigation

export default {
    name: 'ServicePage',
    components: { LazyImage },
    setup() {
        const router = useRouter(); // Initialize router

        const goBack = () => {
            router.back(); // Go back to the previous page
        };

        return {
            goBack
        };
    },
    data() {
        return {
            serviceContent: {},
        };
    },
    created() {
        const serviceName = this.$route.params.serviceName;
        this.serviceContent = ServicesContent.find((service) => service.name === serviceName);
    },
};
</script>

<style scoped>
/* Scoped styles if needed */
</style>
